<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <!-- <span class="a-fs-16 a-fw-700">账期详情</span> -->
        </div>
        <el-card class="el-main">
            <div id="leSearch"></div>
            <le-pagview ref="financeZqDetails" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financeZqDetailsList">
                <el-table ref="financeZqDetailsList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="orderSn" label="订单编号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="transactionId" label="三方支付号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.transactionId?row.transactionId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="balanceDateText" label="订单类型" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="payChannel" 
                        label="支付渠道" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderPrice" label="订单金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payAmount" label="总分账金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="商户分账金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.incomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="orderStatus" 
                        label="订单状态" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderStatus | initDic(orderStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="payStatus" 
                        label="支付状态" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="incomeStatus" 
                        label="入账状态" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeStatus | initDic(enterAccountStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="商户" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomeName" label="项目" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeName?row.incomeName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="站点" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName?row.stationName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="message" label="说明" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.message?row.message:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.backOrderIncomePage,
                    method: "post",
                    params: {
                        paymentDaysId: this.$route.query.id,
                        startDate: this.$route.query.startDate,
                        endDate: this.$route.query.endDate,
                    },
                    freshCtrl: 1,
                },
                orderTypeDic: [],//订单类型
                payChannelDic: [],//支付渠道
                orderStatusDic: [],//订单状态
                payStatusDic: [],// 支付状态
                enterAccountStatusDic: [],//入账状态
            }
        },
        created () {
            this.$getDic('incomeOrderType').then(res=>{ this.orderTypeDic = res; })
            this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
            this.$getDic('orderStatus').then(res=>{ this.orderStatusDic = res; })
            this.$getDic('payStatus').then(res=>{ this.payStatusDic = res; })
            this.$getDic('incomeStatus').then(res=>{ this.enterAccountStatusDic = res; })
        },  
        mounted () {
            
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                // this.pageParam.params = {
                //     isConfirm: '',
                //     companyIds: [],
                //     balanceDateStart: '',
                //     balanceDateEnd: '',
                // };
                // this.handlerSearch()
            },
            handlerSearch() {
                // this.$refs['financeZqDetails'].pageNum = 1
                // this.pageParam.freshCtrl++;
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>